import { Autocomplete, FormControl, TextField } from "@mui/material"
import axios from "axios";
import { useEffect, useState } from "react";

const Select = ({name, label, isRequired = false, data, updateData, endpoint}) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const text = label === undefined ? name : label;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const loading = open && options.length === 0;

    useEffect(() => {
        axios.get(API_URL + endpoint, {
        }).then(response => {
            setOptions(response.data);
            // console.log(options);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            
        });
    }, [API_URL, endpoint]);

    useEffect(() => {
        setSelectedValue(options.find(obj => obj?.id === data?.id) || null);
    }, [options, data, name]);
  
    const handleOnChange = (event, value, reason) => {
        setSelectedValue(value);
        updateData(value || null);
    };
    

    return (
        <div className="m-5 grid grid-cols-1 gap-x-6 gap-y-8">
            <FormControl fullWidth>
                <label
                htmlFor={name}
                className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                {text.charAt(0).toUpperCase() + text.slice(1)}:
                </label>
                <div className="mt-2">
                    <Autocomplete
                        size="small"
                        includeInputInList
                        disableClearable
                        open={open}
                        onOpen={() => {
                        setOpen(true);
                        }}
                        onClose={() => {
                        setOpen(false);
                        }}
                        onChange={handleOnChange}
                        loading={loading}
                        options={options}
                        id={name}
                        name={name}
                        value={selectedValue}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        sx={{
                            height: '38px',
                        }}
                        renderInput={(params) => (
                        <TextField
                            required={isRequired}
                            {...params}
                            sx={{ height: '38px', borderRadius: 0 }}
                        />
                        )}
                    />
                </div>
            </FormControl>
        </div>
      );
};

export default Select;
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import Input from "../../components/Input";

const User = () => {
    const { setIsLoggedIn, cookies } = useContext(AuthContext);
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loginSearch, setLoginSearch] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
  
    const columns = [
      { field: 'id', headerName: 'ID', width: 20, disableColumnMenu: true, sortable: false},
      {
        field: 'login',
        headerName: 'Login',
        disableColumnMenu: true,
        sortable: false,
        flex: 1
      },
      {
        field: 'role',
        headerName: 'Rola',
        disableColumnMenu: true,
        sortable: false,
        flex: 1
      },
      {
        field: 'verified',
        headerName: 'Czy zweryfikowany',
        disableColumnMenu: true,
        sortable: false,
        flex: 1
      },
      {
        field: 'usun',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        width: 25,
        renderCell: (params) => {
          const onClick = async e => {
            e.stopPropagation(); // don't select this row after clicking
            axios.get(API_URL + '/user/verify/' + params.id + '?value=' + (!params.row.verified), {
              headers: {
                Authorization: 'Bearer '+ cookies.access_token
              }
            })
            .then(response => {
              console.log(response);
              getData();
            })
            .catch(err => {
              console.log(err);
              alert(err);
            });
          };
    
          return <FontAwesomeIcon icon={params.row.verified ? faCheckSquare : faSquare} onClick={onClick} style={{color: '#3c8dbc'}}/>;
        }
      },
    ];
    const getData = useCallback(async () => {
        setLoading(true);
        axios.get(API_URL + '/user/all?login=' + loginSearch,  {
          headers: {
            Authorization: 'Bearer ' + cookies.access_token
          }
        })
        .then(response => {
          setLoading(false);
          setRows(response.data);
          console.log(response);
        })
        .catch(err => {
          console.log(err);
          setIsLoggedIn(false);
          navigate('/login');
        });
      }, [API_URL, cookies.access_token, navigate, setIsLoggedIn, loginSearch]);
    
      useEffect(() => {
        getData();
      }, [loginSearch, getData]);
    
      return (
            <main className='flex flex-col'>
                <div style={{height: '120px'}}></div>
                <div className='grid md:grid-cols-5'>
                    <div></div>
                    <div className='col-span-3'>
                        <div className="bg-white py-1 my-4">
                            <Input name={'login'} label={'Wyszukaj po loginie'} data={loginSearch} updateData={setLoginSearch} />
                        </div>
                        <DataGrid
                            // getRowId={(row) => row.login}
                            sx={{backgroundColor: 'white'}}
                            rowSelection={false}
                            loading={loading}
                            rows={rows}
                            columns={rows.length ? columns : []}
                            initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                            }}
                            pageSizeOptions={[10, 25]}
                            disableRowSelectionOnClick
                            sortingMode="server"
                        />
                    </div>
                </div>
            </main>
      );
}

export default User;
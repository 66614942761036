import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import TextArea from "../../components/TextArea";
import Input from "../../components/Input";

const InfoForm = () => {
    const { name } = useParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [nameI, setNameI] = useState('');
    const isNewRecord = name === 'create';

    const getInfo = useCallback(async () => {
        axios.get(API_URL + '/info/' + name, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setText(body.text);
            setNameI(body.name);
        })
        .catch(e => {
            console.log(e);
        });
    }, [API_URL, cookies.access_token, name]);

    const saveInfo = async e => {
        e.preventDefault();
        if(isNewRecord) {
            axios.post(API_URL + '/info', JSON.stringify({
                name: nameI,
                text: text
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/infos');
              }
            })
            .catch(e => {
              console.log(e);
            //   setErrMsg(e.response.data.error);
            });
        } else {
            axios.put(API_URL + '/info/' + name, JSON.stringify({
                text: text
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/infos');
              }
            })
            .catch(e => {
              console.log(e);
              alert(e);
            //   setErrMsg(e.response.data.error);
            });
        }
      }

    useEffect(() => {
        console.log('Name: ' + name);
        if(!isNewRecord) {
            getInfo();
        }
    }, [isNewRecord, getInfo, name]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{height: '100vh'}}>
            <div></div>
            <form onSubmit={saveInfo} className="bg-white rounded-md p-12 col-span-3">
            <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowa informacja:' : 'Aktualizacja: ' + name}
                    </strong>
                </h2>
                <Input data={nameI} updateData={setNameI} name='name' label='Nazwa' disabled={!isNewRecord} readOnly={!isNewRecord}/>
                <TextArea data={text} updateData={setText} name='text' label='Tekst'/>
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath='/infos'/>
                </div>
            </form>
        </section>
    );
}

export default InfoForm;
import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const MyAppBar = () => {
    const pages = [
        {name: 'Artykuły', path: '/'},
        {name: 'Aktualności', path: '/news'},
        {name: 'Kategorie artykułów', path: '/categories'},
        {name: 'Kategorie newsów', path: '/news-categories'},
        {name: 'Linki', path: '/links'},
        {name: 'Kategorie linków', path: '/link-categories'},
        // {name: 'Powiadomienie', path: '/notification'},
        {name: 'Informacje', path: '/infos'},
        {name: 'Użytkownicy', path: '/users'},
        {name: 'Kalendarz', path: '/calendar'},
    ];
    const [anchorElNav, setAnchorElNav] = useState(null);
  
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

  return (
    <AppBar position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                {/* <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
                >
                    Polski-Wiatrak
                </Typography> */}

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                <FontAwesomeIcon icon={faBars} />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                    display: { xs: 'block', md: 'none' },
                    }}
                >
                    {pages.map((page) => (
                        <Link to={page.path} key={page.name}>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page.name}</Typography>
                            </MenuItem>
                        </Link>
                    ))}
                </Menu>
                </Box>
                <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    // flexGrow: 0,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
                >
                    Polski-Wiatrak
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                        <Link to={page.path} key={page.name}>
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        </Link>

                    ))}
                </Box>
                <Link to='logout'>
                    <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">Wyloguj&nbsp;</Typography>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                    </MenuItem>
                </Link>
            </Toolbar>
        </Container>
    </AppBar>
  );
}

export default MyAppBar;
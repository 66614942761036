import { useContext, useState } from "react"
import BackButton from "../../components/BackButton"
import Input from "../../components/Input"
import SaveButton from "../../components/SaveButton"
import axios from "axios"
import AuthContext from "../../providers/AuthContext"
import { useNavigate } from "react-router-dom"

const Notification = () => {

    const API_URL = process.env.REACT_APP_API_URL;
    const { cookies } = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [payload, setPayload] = useState('');
    const navigate = useNavigate();

    const sendNotification = e => {
        e.preventDefault();
        axios.post(API_URL + '/notification/send',JSON.stringify({title, text, payload}), {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token,
                "Content-Type": 'application/json'
            }
        }).then(response => {
            navigate('/');
        }).catch(err => {
            alert('Błąd wysłania powiadomienia, sprawdź konsole po więcej informacji.');
            console.log(err);
        });
    }

    return (
        <section className="items-center grid md:grid-cols-5" style={{height: '100vh'}}>
            <div></div>
            <form onSubmit={sendNotification} className="bg-white rounded-md p-12 col-span-3">
                <h2 className="text-black">
                    <strong>
                        Wyślij powiadomienie
                    </strong>
                </h2>
                <Input data={title} updateData={setTitle} name='title' label='tytuł' isRequired={true}/>
                <Input data={text} updateData={setText} name='text' label='tekst' isRequired={true}/>
                <Input data={payload} updateData={setPayload} name='payload' label='payload'/>
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton label="Wyślij" />
                    <BackButton returnPath='/'/>
                </div>
            </form>
        </section>
    );
}

export default Notification;
import { Link } from "react-router-dom"

const AddButton = ({path}) => {
    return (
        <Link to={path}>
            <button type="button" className="flex w-full justify-center rounded-md bg-[#009900] hover:bg-[#008800] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Dodaj</button>
        </Link>
    )
  }
  
  export default AddButton;
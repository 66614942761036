import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import FaSelect from "../../components/FaSelect";

const CategoryForm = () => {
    const { id } = useParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const isNewRecord = !(id > 0);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [icon, setIcon] = useState('');

    const getCategory = useCallback(async () => {
        axios.get(API_URL + '/category/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setName(body.name);
            setIcon(body.icon);
        })
        .catch(e => {
            console.log(e);
        })
    }, [API_URL, cookies.access_token, id]);

    const saveCategory = async e => {
        e.preventDefault();
        if(isNewRecord) {
            axios.post(API_URL + '/category', JSON.stringify({
                name: name,
                icon: icon
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/categories');
              }
            })
            .catch(e => {
              console.log(e);
            //   setErrMsg(e.response.data.error);
            });
        } else {
            axios.put(API_URL + '/category/' + id, JSON.stringify({
                name: name,
                icon: icon
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/categories');
              }
            })
            .catch(e => {
              console.log(e);
              alert(e);
            //   setErrMsg(e.response.data.error);
            });
        }
      }

    useEffect(() => {
        if(!isNewRecord) {
            getCategory();
        }
    }, [isNewRecord, getCategory]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{height: '100vh'}}>
            <div></div>
            <form onSubmit={saveCategory} className="bg-white rounded-md p-12 col-span-3">
            <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowa kategoria' : 'Aktualizacja kategorii nr ' + id}
                    </strong>
                </h2>
                <Input data={name} updateData={setName} name='name' label='tytuł'/>
                <FaSelect data={icon} updateData={setIcon} name='icon' label='Ikona' endpoint='https://api.fontawesome.com'/>
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath='/categories'/>
                </div>
            </form>
        </section>
    );
}

export default CategoryForm;
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import FaSelect from "../../components/FaSelect";
import Select from "../../components/Select";

const LinkForm = () => {
    const { id } = useParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const isNewRecord = !(id > 0);
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState({});
    const [category, setCategory] = useState({});

    const getLink = useCallback(async () => {
        axios.get(API_URL + '/link/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setLink(body.link);
            setName(body.name);
            setIcon(body.icon);
            setDescription(body.description);
            setCategory(body.category);
        })
        .catch(e => {
            console.log(e);
        });
    }, [API_URL, cookies.access_token, id]);

    const saveLink = async e => {
        e.preventDefault();
        if(isNewRecord) {
            axios.post(API_URL + '/link', JSON.stringify({
                link: link,
                icon: icon,
                description: description,
                category_id: category.id,
                name: name
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/links');
              }
            })
            .catch(e => {
              console.log(e);
            //   setErrMsg(e.response.data.error);
            });
        } else {
            axios.put(API_URL + '/link/' + id, JSON.stringify({
                link: link,
                icon: icon,
                description: description,
                category_id: category.id,
                name: name
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
              console.log(response);
              if(response.status === 201 || response.status === 200) {
                navigate('/links');
              }
            })
            .catch(e => {
              console.log(e);
              alert(e);
            //   setErrMsg(e.response.data.error);
            });
        }
      }

    useEffect(() => {
        if(!isNewRecord) {
            getLink();
        }
    }, [isNewRecord, getLink]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{height: '100vh'}}>
            <div></div>
            <form onSubmit={saveLink} className="bg-white rounded-md p-12 col-span-3">
            <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowy link' : 'Aktualizacja linku nr ' + id}
                    </strong>
                </h2>
                <div className="grid md:grid-cols-2">
                    <Input data={name} updateData={setName} name='name' label='Nazwa'/>
                    <Input data={link} updateData={setLink} name='link' label='Link'/>
                    <Input data={description} updateData={setDescription} name='description' label='Krótki opis'/>
                    <FaSelect data={icon} updateData={setIcon} name='icon' label='Ikona' endpoint='https://api.fontawesome.com'/>
                    <Select data={category} updateData={setCategory} name='category_id' label='Kategoria' endpoint='/link-category/all'/>
                </div>
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath='/links'/>
                </div>
            </form>
        </section>
    );
}

export default LinkForm;
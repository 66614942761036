import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../providers/AuthContext';

const Logout = () => {
const navigate = useNavigate();
const { setIsLoggedIn, setCookie } = useContext(AuthContext);

  useEffect(() => {
    setIsLoggedIn(false);
    setCookie('access_token', '', { path: '/',  expires: 0});
    navigate('/login');
  }, [navigate, setCookie, setIsLoggedIn]);
  return (
    <></>
  );
}
export default Logout;
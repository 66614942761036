import { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthContext from './providers/AuthContext';
import axios from 'axios';
import LoginForm from './pages/LoginForm';
import Logout from './pages/Logout';
import { useCookies } from 'react-cookie';
import Link from './pages/Link/Link';
import MyAppBar from './components/MyAppBar';
import LinkForm from './pages/Link/LinkForm';
import Article from './pages/Article/Article';
import ArticleForm from './pages/Article/ArticleForm';
import Category from './pages/Category/Category';
import CategoryForm from './pages/Category/CategoryForm';
import LinkCategory from './pages/Link-Category/LinkCategory';
import LinkCategoryForm from './pages/Link-Category/LinkCategoryForm';
import Notification from './pages/Notification/Notification';
import NewsCategory from './pages/NewsCategory/NewsCategory';
import NewsCategoryForm from './pages/NewsCategory/NewsCategoryForm';
import Info from './pages/Info/Info';
import InfoForm from './pages/Info/InfoForm';
import User from './pages/User/User';
import News from './pages/News/News';
import NewsForm from './pages/News/NewsForm';
import Calendar from './pages/Calendar/Calendar';
import CalendarForm from './pages/Calendar/CalendarForm';

function App() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cookies, setCookie] = useCookies(['access_token']);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      axios.get(API_URL + '/article/all', {
        headers: {Authorization: 'Bearer ' + cookies.access_token}
      })
      .then(response => { 
        setIsLoggedIn(true);
        navigate(location.pathname.indexOf('login') === -1 ? location.pathname : '/');
      })
      .catch(err => {
        console.log(err);
        navigate('/login');
      });
    }
  }, [isLoggedIn, navigate, API_URL, location.pathname, cookies.access_token]);

  return (
    <div className="App" style={{height: '100vh'}}>
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, cookies, setCookie}}>
          {(isLoggedIn) ? <MyAppBar/> : <></>}
            <Routes>
                <Route index element={<Article/>}/>
                <Route path='/login' element={<LoginForm />}/>
                <Route path='/logout' element={<Logout />}/>
                <Route path='/article/create' element={<ArticleForm />}/>
                <Route path='/article/:id' element={<ArticleForm />}/>
                <Route path='/categories' element={<Category />}/>
                <Route path='/categories/create' element={<CategoryForm />}/>
                <Route path='/categories/:id' element={<CategoryForm />}/>
                <Route path='/news-categories' element={<NewsCategory />}/>
                <Route path='/news-categories/create' element={<NewsCategoryForm />}/>
                <Route path='/news-categories/:id' element={<NewsCategoryForm />}/>
                <Route path='/links' element={<Link />}/>
                <Route path='/links/create' element={<LinkForm />}/>
                <Route path='/links/:id' element={<LinkForm />}/>
                <Route path='/link-categories' element={<LinkCategory />}/>
                <Route path='/link-categories/create' element={<LinkCategoryForm />}/>
                <Route path='/link-categories/:id' element={<LinkCategoryForm />}/>
                <Route path='/infos' element={<Info />}/>
                <Route path='/infos/:name' element={<InfoForm />}/>
                <Route path='/notification' element={<Notification />}/>
                <Route path='/news' element={<News />}/>
                <Route path='/news/create' element={<NewsForm />}/>
                <Route path='/news/:id' element={<NewsForm />}/>
                <Route path='/users' element={<User />}/>
                <Route path='/calendar' element={<Calendar />}/>
                <Route path='/calendar/create' element={<CalendarForm />}/>
                <Route path='/calendar/:id' element={<CalendarForm />}/>
            </Routes>
        </AuthContext.Provider>
    </div>
  );
}

export default App;

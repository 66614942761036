import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../providers/AuthContext";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';


const Calendar = () => {
    const { setIsLoggedIn, cookies } = useContext(AuthContext);
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const renderEventContent = (eventInfo) => {
        return(
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    }

    const onDateClick = (info) => {
        console.log(info);
        navigate(`/calendar/create?date=${info.dateStr}`);
    }

    const getData = useCallback(async (year) => {
        setLoading(true);
        try {
            const response = await axios.get(API_URL + `/calendar/year/${year}`,  {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token
                }
            });
            setLoading(false);
            console.log(response.data);
            return response.data;
        } catch(err) {
            console.log(err);
            setIsLoggedIn(false);
            navigate('/login');
        } 
    }, [API_URL, cookies.access_token, navigate, setIsLoggedIn]);

    const loadEvents = useCallback(async (year) => {
        const events1 = await getData(year - 1);
        const events2 = await getData(year);
        setEvents(events1.concat(events2));
    }, [getData]);
    
    useEffect(() => {
        loadEvents(year);
    }, [loadEvents, year]);

    return (
        <main className='flex flex-col'>
            <div style={{height: '60px'}}></div>
            <div className='grid md:grid-cols-5'>
                <div></div>
                <div className='col-span-3 bg-white text-black'>
                    <FullCalendar 
                        loading={() => loading}
                        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        eventContent={renderEventContent}
                        events={events}
                        eventClick={e => {
                            navigate(`/calendar/${e.event.id}`);
                        }}
                        headerToolbar= {{
                            left: 'prev,today,next',
                            center: 'title',
                            right: 'dayGridMonth'
                        }}
                        datesSet={e => {
                            setYear(new Date(e.endStr).getFullYear())
                        }}
                        dateClick={onDateClick}
                    />
                </div>
            </div>
        </main>
    );
}
export default Calendar;
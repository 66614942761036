import { useEffect, useState, useRef, useContext } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../providers/AuthContext';

const LoginForm = () => {
    const { setIsLoggedIn, setCookie } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const errRef = useRef();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        setLogin(login);
    }, [login]);
    
    useEffect(() => {
        setPassword(password);
    }, [password]);
    
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL + '/user/login',
                JSON.stringify({login, password}), {
                    headers: {
                        "Content-Type": 'application/json'
                    }
                }
            );
            setIsLoggedIn(true);
            navigate('/');
            let expires = new Date();
            expires.setTime(expires.getTime() + (3600 * 24 * 30 * 1000));
            setCookie('access_token', response.data.access_token, { path: '/',  expires, secure: true});
        } catch(err) {
            if(err.response === undefined) {
                setErrMsg('Brak połączenia z serwerem');
            } 
            else if(err.response.status === 401) {
                setErrMsg('Błędny login lub hasło.');
            }
            else {
                setErrMsg('Wystąpił błąd.')
            }
        }
    }
    

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 -mt-14">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">Zaloguj się do konta</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="text" className="block text-sm font-medium leading-6">Login</label>
                    </div>
                    <div className="mt-2">
                        <input onChange={(e) => setLogin(e.target.value)} id="text" name="text" type="text" autoComplete="text" required className="ps-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password" className="block text-sm font-medium leading-6">Hasło</label>
                    </div>
                    <div className="mt-2">
                        <input onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" autoComplete="current-password" required className={`ps-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`} />
                    </div>
                </div>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div>
                    <button type="submit" className="flex w-full justify-center rounded-md bg-[#3c8dbc] hover:bg-[#2b7cab] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Zaloguj</button>
                </div>
            </form>
        </div>
    </div>
  );
}

export default LoginForm;
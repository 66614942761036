import { useNavigate } from "react-router-dom"

const BackButton = ({returnPath}) => {
  const navigate = useNavigate();
    return (
    <button type="button" onClick={() => {
        navigate(returnPath);
    }} className="flex w-full justify-center rounded-md bg-blue-600 hover:bg-blue-500 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Powrót</button>
  )
}

export default BackButton;
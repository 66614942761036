import { Checkbox, FormControlLabel } from "@mui/material"

const CheckboxInput = ({name, label, data, updateData}) => {
  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    updateData(newValue);
  };
  return (
    <div className="flex items-center justify-center text-black">
    <FormControlLabel
        label={label}
        control={
          <Checkbox
            name={name}
            checked={data}
            onChange={handleCheckboxChange}
          />
        }
    />  
    </div>
  )
}

export default CheckboxInput;
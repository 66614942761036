import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from '../../providers/AuthContext';
import { useNavigate } from 'react-router-dom';
import AddButton from '../../components/AddButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const News = () => {

  const { setIsLoggedIn, cookies } = useContext(AuthContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  const columns = [
    { field: 'id', headerName: 'ID', width: 20, disableColumnMenu: true, sortable: false},
    {
      field: 'title',
      headerName: 'Tytuł',
      disableColumnMenu: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'text',
      headerName: 'Treść',
      disableColumnMenu: true,
      sortable: false,
      flex: 1
    },
    {
      field: 'added',
      headerName: 'Dodano',
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{new Date(params.row.added).toUTCString()}</span>;
      },
    },
    {
      field: 'usun',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      width: 25,
      renderCell: (params) => {
        const onClick = async e => {
          e.stopPropagation(); // don't select this row after clicking
          axios.delete(API_URL + '/news/' + params.id, {
            headers: {
              Authorization: 'Bearer '+ cookies.access_token
            }
          })
          .then(response => {
            console.log(response);
            getData();
          })
          .catch(err => {
            console.log(err);
            alert(err);
          });
        };
  
        return <FontAwesomeIcon icon={faTrash} onClick={onClick} style={{color: '#3c8dbc'}}/>;
      }
    },
  ];


  const getData = useCallback(async () => {
    setLoading(true);
    axios.get(API_URL + '/news/all?size=999',  {
      headers: {
        Authorization: 'Bearer ' + cookies.access_token
      }
    })
    .then(response => {
      setLoading(false);
      setRows(response.data.content);
    })
    .catch(err => {
      console.log(err);
      setIsLoggedIn(false);
      navigate('/login');
    });
  }, [API_URL, cookies.access_token, navigate, setIsLoggedIn]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
        <main className='flex flex-col'>
            <div style={{height: '120px'}}></div>
            <div className='grid md:grid-cols-5'>
                <div></div>
                <div className='col-span-3'>
                    <AddButton path='/news/create'/>
                    <DataGrid
                        sx={{backgroundColor: 'white'}}
                        onRowClick={(e) => {
                        navigate('/news/' + e.id);
                        }}
                        rowSelection={false}
                        loading={loading}
                        rows={rows}
                        columns={rows.length ? columns : []}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                        }}
                        pageSizeOptions={[10, 25]}
                        disableRowSelectionOnClick
                        sortingMode="server"
                        getRowClassName={(params) => (params.row.visible ? 'active' : 'non-active')}
                    />
                </div>
            </div>
        </main>
  );
}

export default News;